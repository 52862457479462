const isProduction = process.env.VUE_APP_NODE_ENV === 'production';

export function log(...args) {
	console.log("開發環境調用 isProduction="+isProduction);
	if (!isProduction) {
		//開發環境調用
		console.log(...args);
	}
}

export function error(...args) {
	//開發環境及正式環境調用
	console.error(...args);
}